<template>
  <b-card-actions
    ref="domainList"
    no-actions
    no-body
  >
    <div class="mx-1">
      <b-row class="mt-1">
        <b-col cols="4">
          <HeroInputText
            id="filter"
            v-model="tableConfig.filter"
            placeholder="Search By Campaign"
            @input="doTableFilter(500)"
          />
        </b-col>
        <b-col cols="2">
          <HeroTablePerPage
            v-model="tableConfig.perPage"
            :options="tableConfig.perPageOptions"
            @input="doTableFilter(100)"
          />
        </b-col>
        <b-col cols="4"></b-col>
        <b-col cols="2">
          <HeroButtonAction
            :disabled="isexporting"
            type="button"
            variant="primary"
            @click="exportDomain"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          >
            {{ isexporting ? 'Exporting...' : 'Export' }}
          </HeroButtonAction>
        </b-col>
      </b-row>
    </div>
    <b-row>
      <b-col cols="12">
        <b-button type="button" class="mx-1 mr-1" variant="primary" @click="clickStatus()">All ({{totalRows}})</b-button>
        <b-button type="button" class="mx-1 mr-1" variant="success" @click="clickStatus('active')">Active ({{totalActiveRows}})</b-button>
        <b-button type="button" class="mx-1 mr-1" variant="secondary" @click="clickStatus('paused')">Paused ({{totalPausedRows}})</b-button>
        <b-button type="button" class="mx-1 mr-1" variant="danger" @click="clickStatus('inactive')">Inactive ({{totalInactiveRows}})</b-button>
        <b-table
          show-empty
          :items="items"
          :fields="tableConfig.fields"
          striped
          responsive
          bordered
          :sticky-header="true"
          :no-border-collapse="true"
          style="font-weight: normal; font-size: 12px;"
          :per-page="0"
          :sort-by="tableConfig.sortBy"
          :sort-direction="tableConfig.sortDirection"
          :no-sort-reset="true"
          :no-local-sorting="true"
          @sort-changed="doTableSort"
          class="mt-2"
        >
          <template #head(domain_expire_date)="data">
            <div class="span span:hover">
              {{ data.label }}
            </div>
          </template>
          <template #head(status)="data">
            <div class="span span:hover">
              {{ data.label }}
            </div>
          </template>
          <template #head(actual_campaign_end_date)="data">
            <div class="span span:hover">
              {{ data.label }}
            </div>
          </template>
          <template #cell(name)="data">
            <router-link :to="{ name: 'campaign-detail', params: { id: data.item.id }}">
              {{ data.item.name }}
            </router-link>
          </template>
          <template #cell(domain_expire_date)="data">
            <span v-if="data.item.domain_expire_date" :class="{ danger: data.item.isDomainExpired }">
              {{ data.item.domain_expire_date }}
            </span>
            <span v-else-if="data.item.target_url">
              Not Found
            </span>
          </template>
          <template #cell(did_phone)="data">
            {{ Array.isArray(data.item.did_phone) ? data.item.did_phone.join(', ') : data.item.did_phone }}
          </template>
        </b-table>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
        <HeroTablePagination
          v-model="tableConfig.currentPage"
          :per-page="tableConfig.perPage"
          :total-rows="tableConfig.totalRows"
          @input="doTableFilter(100)"
        />
      </b-col>
      <b-col cols="12" sm="6" class="d-flex align-items-top justify-content-center justify-content-sm-end">
        <HeroTableStatus :per-page="tableConfig.perPage" :total-rows="tableConfig.totalRows" />
      </b-col>
    </b-row>
  </b-card-actions>
</template>
<script>
import moment from 'moment'
import AxiosService from '@/services/AxiosService'
import DomainFactory from '@/factories/Domain'
import Constant from '@/utils/Constant'
import Ripple from 'vue-ripple-directive'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import HeroInputText from '@/views/components/form/inputs/HeroInputText.vue'
import HeroTablePerPage from '@/views/components/table/HeroTablePerPage.vue'
import HeroTablePagination from '@/views/components/table/HeroTablePagination.vue'
import HeroTableStatus from '@/views/components/table/HeroTableStatus.vue'
import HeroButtonAction from '../components/form/buttons/HeroButtonAction.vue'

export default {
  components: {
    BCardActions,
    HeroInputText,
    HeroTablePerPage,
    HeroTablePagination,
    HeroTableStatus,
    HeroButtonAction,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      items: [],
      totalRows: 0,
      totalActiveRows: 0,
      totalInactiveRows: 0,
      totalPausedRows: 0,
      isexporting: false,
      tableConfig: {
        filter: '',
        currentPage: 1,
        perPage: 25,
        perPageOptions: [5, 10, 25, 50, 75, 100],
        totalRows: 0,
        sortBy: 'domain_expire_date',
        sortDirection: 'asc',
        timeInterval: moment(),
        statusFilter: '',
        fields: [
          {
            label: 'Campaign Name',
            key: 'name',
            sortable: true,
          },
          {
            label: 'Domain',
            key: 'target_url',
            sortable: true,
          },
          {
            label: 'Domain Expire Date',
            key: 'domain_expire_date',
            sortable: true,
          },
          {
            label: 'DID Number',
            key: 'did_phone',
            sortable: true,
            tdClass: 'didNumber',
          },
          {
            label: 'Campaign Status',
            key: 'status',
            sortKey: 'campaign_status_id',
            sortable: true,
          },
          {
            label: 'Campaign End Date',
            key: 'actual_campaign_end_date',
            sortable: true,
          },
        ],
      },
    }
  },
  async mounted() {
    await this.doLoadData()
  },
  methods: {
    async clickStatus(status) {
      this.tableConfig.statusFilter = status
      await this.doLoadData()
    },

    doTableFilter(timeout) {
      setTimeout(() => {
        if (moment().diff(this.tableConfig.timeInterval, 'milliseconds') > timeout) {
          this.tableConfig.timeInterval = moment()
          this.doLoadData()
        }
      }, timeout)
    },

    doTableSort(event) {
      this.tableConfig.sortBy = event.sortBy
      this.tableConfig.sortDirection = event.sortDesc ? 'desc' : 'asc'
      this.doLoadData()
    },

    async doLoadData() {
      this.$refs.domainList.showLoading = true
      const {
        perPage, currentPage, filter, sortBy, sortDirection, statusFilter,
      } = this.tableConfig
      const qparam = {
        perPage, page: currentPage, q: filter, orderByColumn: sortBy, orderByDirection: sortDirection, statusFilter,
      }

      const countStatusQparam = {
        perPage, page: currentPage, q: filter, orderByColumn: sortBy, orderByDirection: sortDirection,
      }

      const [
        countResponse,
        response,
      ] = await Promise.all([
        AxiosService.get(Constant.apiURL.domain.count_status, countStatusQparam),
        AxiosService.get(Constant.apiURL.domain.list, qparam),
      ])
      if (response.status === 'success') {
        this.tableConfig.currentPage = Number(response.data.current_page)
        this.tableConfig.perPage = Number(response.data.per_page)
        this.tableConfig.totalRows = Number(response.data.total)
        this.items = DomainFactory.createFromJsonArray(response.data.data)
      } else {
        this.tableConfig.currentPage = 1
        this.tableConfig.totalRows = 0
        this.items = []
      }

      if (countResponse.status === 'success') {
        this.totalRows = countResponse?.data?.all
        this.totalActiveRows = countResponse?.data?.active
        this.totalInactiveRows = countResponse?.data?.inactive
        this.totalPausedRows = countResponse?.data?.paused
      } else {
        this.totalActiveRows = 0
        this.totalInactiveRows = 0
        this.totalPausedRows = 0
        //
      }
      this.$refs.domainList.showLoading = false
    },

    async exportDomain() {
      this.isexporting = true
      const {
        filter, sortBy, sortDirection, statusFilter,
      } = this.tableConfig
      const qparam = {
        q: filter, orderByColumn: sortBy, orderByDirection: sortDirection, statusFilter,
      }
      const response = await AxiosService.get(Constant.apiURL.domain.export, qparam)
      if (response.status === 'success') {
        window.open(response.data.file, '_blank')
      }
      this.isexporting = false
    },
  },
}
</script>
<style>
  .didNumber {
    max-width: 300px;
  }

  .danger {
    color: red;
  }
  .span {
    width: 100px; /* can be 100% ellipsis will happen when contents exceed it */
    display: inline-block;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .span:hover {
    white-space: normal;
    /* or:
    width: auto
    */
  }
</style>
